/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `M` - Main * `C` - Checkout
 */
export type BannerPositionEc3Enum = 'M' | 'C';

export const BannerPositionEc3Enum = {
    M: 'M' as BannerPositionEc3Enum,
    C: 'C' as BannerPositionEc3Enum
};

