/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `KG` - กิโลกรัม * `G` - กรัม * `ML` - มิลลิลิตร * `L` - ลิตร
 */
export type WeightUnitEnum = 'KG' | 'G' | 'ML' | 'L';

export const WeightUnitEnum = {
    Kg: 'KG' as WeightUnitEnum,
    G: 'G' as WeightUnitEnum,
    Ml: 'ML' as WeightUnitEnum,
    L: 'L' as WeightUnitEnum
};

