/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ACTIVE` - Active * `EXPIRED` - Expired * `UPCOMING` - Upcoming
 */
export type BannerStatusEnum = 'ACTIVE' | 'EXPIRED' | 'UPCOMING';

export const BannerStatusEnum = {
    Active: 'ACTIVE' as BannerStatusEnum,
    Expired: 'EXPIRED' as BannerStatusEnum,
    Upcoming: 'UPCOMING' as BannerStatusEnum
};

